import React, {useEffect} from 'react';
import CommunicationText from "../component/CommunicationText";
import {defaultMessages, editorial_responsibility_title, link_impressum} from "../i18n/messages";
import {Message} from "../i18n/Message";
import {Disclaimer} from "./Disclaimer";
import NavigationHeader from "../layout/NavigationHeader";
import {Trademarks} from "./Trademarks";

export function LegalNotice() {

    useEffect(() => {
        window.scrollTo({top: 0});
    }, []);

    return (
        <div>
            <NavigationHeader title="Home" backPath="/"/>
            <div className="padding-bottom-l">
                <div>
                    <h1><Message messageKey={link_impressum} defaultMessage={defaultMessages[link_impressum]}/></h1>
                    <p>
                        Enis Brulic - netdriven<br/>
                        Ruhrorter Str. 46<br/>
                        D-68219 Mannheim
                    </p>
                </div>
                <div>
                    <p>
                        <CommunicationText title="Tel: " content="+49 621 9766 5585"/>
                        <CommunicationText title="E-Mail: " content="contact@netdriven.de"/>
                    </p>
                </div>
                <div>
                    <p>USt-Id-Nr: DE282680766</p>
                </div>
                <div style={{paddingTop: "5px"}}>
                    <h4><Message messageKey={editorial_responsibility_title}
                                 defaultMessage={defaultMessages[editorial_responsibility_title]}/></h4>
                    <p>
                        Enis Brulic<br/>
                        Ruhrorter Str. 46<br/>
                        D-68219 Mannheim
                    </p>
                </div>
            </div>
            <div className="padding-bottom-l">
                <Disclaimer/>
            </div>
            <div className="padding-bottom-l">
                <Trademarks/>
            </div>
        </div>

    );
}
