import React from 'react';
import CommunicationText from "../component/CommunicationText";
import useWindowDimensions from "../utils/useWindowDimensions";
import {
    Card,
    CardActions,
    CardBody,
    CardHeader,
    CardImage,
    CardSubtitle,
    CardTitle
} from "@progress/kendo-react-layout";
import {SvgIcon} from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import MailTo from "../component/MailTo";

export function Contact() {

    const {isResponsive} = useWindowDimensions();

    const profilUrl = "https://res.cloudinary.com/arrowhacks/image/upload/ar_1:1,b_rgb:000000,bo_0px_solid_rgb:000000,c_fill,g_auto,r_max,w_166/v1608245028/bullseye/20120417-174433-0012-2-web.jpg";

    return (
        <div>
            <div className={isResponsive ? "column-direction justify-center" : "row-direction justify-center"}>
                <div>
                    <Card orientation={isResponsive ? "vertical" : "horizontal"}>
                        <CardImage src={profilUrl} className="profile-logo" style={{maxWidth: "200px"}}/>
                        <div className="k-vbox padding-left-l">
                            <CardHeader>
                                <CardBody className="margin-left-right-l">
                                    <CardTitle>Enis Brulic - netdriven</CardTitle>
                                    <CardSubtitle>Fullstack Software Developer</CardSubtitle>
                                    <hr/>
                                    <p>
                                        Ruhrorter Str. 46<br/>
                                        D-68219 Mannheim
                                    </p>
                                    <p>
                                        <CommunicationText title="Tel: " content="+49 621 9766 5585"/>
                                        <CommunicationText title="E-Mail: " content="enis.brulic@netdriven.de"/>
                                    </p>
                                </CardBody>
                                <CardActions className="action-buttons">
                                    <MailTo email="enis.brulic@netdriven.de" subject="" body="">
                                        <SvgIcon icon={svgIcons['envelopIcon']} size="medium" style={{color: "black"}}/>
                                    </MailTo>
                                    <span>
                                        <a href="https://www.xing.com/profile/Enis_Brulic" target="_blank" rel="me">
                                            <img src="https://www.xing.com/img/buttons/10_en_btn.gif"
                                                 alt="Xing" width="85" height="21px"/>
                                        </a>
                                    </span>
                                    <span>
                                        <a href="http://twitter.com/enisbrulic" target="_blank" rel="me">
                                            <img src="https://github.com/get-icon/geticon/raw/master/icons/twitter.svg"
                                                 alt="Twitter" width="21px" height="21px"/>
                                        </a>
                                    </span>
                                    <span>
                                        <a href="https://github.com/enisbrulic" target="_blank" rel="me">
                                            <img
                                                src="https://github.com/get-icon/geticon/raw/master/icons/github-icon.svg"
                                                alt="Github" width="21px" height="21px"/>
                                        </a>
                                    </span>
                                </CardActions>
                            </CardHeader>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}
